const Verdict = {
  SUBMITTED: 'Submitted',
  IN_QUEUE: 'In Queue',
  PROCESSING: 'Processing',
  ACCEPTED: 'accepted',
  UNKNOWN_ERROR: 'Unknown Error',
  WRONG_ANSWER: 'wrong answer',
  RUNTIME_ERROR: 'runtime error',
  TIME_LIMIT_EXCEEDED: 'time limit exceeded',
  COMPILATION_ERROR: 'compilation error',
  RUNTIME_ERROR_SIGSEGV: 7,
  RUNTIME_ERROR_SIGXFSZ: 8,
  RUNTIME_ERROR_SIGFPE: 9,
  RUNTIME_ERROR_SIGABRT: 10,
  RUNTIME_ERROR_NZEC: 11,
  RUNTIME_ERROR_OTHER: 12,
  INTERNAL_ERROR: 13,
  EXEC_FORMAT_ERROR: 14,
};

export default Verdict;
