/* eslint-disable */
import React from 'react';
import {
  Navigate
} from 'react-router';
import useLogin from '../hooks/useLogin';

function PrivateRoute({ children }) {
  const isLoggedIn = useLogin();
  return (
    <>
      {
        isLoggedIn == 1 ? (children) :
        (
          isLoggedIn == 0 ? ( <div> Loading... </div>)
            :
            (
              <Navigate
                to={{
                  pathname: "/login",
                }}
              />
            )
        )
      }
    </>
  )
}

export default PrivateRoute;
