/* eslint-disable */
import { useState, useEffect } from 'react';
import * as R from 'ramda';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';

function recursivelyTransform(category) {
  category['label'] = category['name'];
  category['value'] = category['name'];
  if (!R.isNil(category.children) && category.children.length > 0) {
    let newChildren = [];
    category.children.forEach(child => {
      newChildren.push(recursivelyTransform(child));
    });
    category['children'] = newChildren;
  }
  return category;
}

function TreeSelect({ data, onChange }) {
  const [transformedData, setTransformedData] = useState({});
  const [selectedNodes, setSelectedNodes] = useState([]);

  useEffect(() => {
    console.log(`selectedNodes = ${JSON.stringify(selectedNodes)}`);
    if (!R.isNil(onChange)) {
      onChange(selectedNodes);
    }
  }, [selectedNodes]);

  useEffect(() => {
    let transformedData = [];
    for(let i = 0; i < data.length; i++) {
      transformedData.push(recursivelyTransform(data[i]));
    }
    setTransformedData(transformedData);
  }, data);

  return (
    <DropdownTreeSelect
      keepChildrenOnSearch
      data={transformedData}
      onChange={(currentNode)  => {
        let nSelectedNodes = [...selectedNodes];
        if (currentNode.checked === true) {
          nSelectedNodes.push(currentNode);
        }
        else {
          nSelectedNodes = R.filter(node => node.name !== currentNode.name, selectedNodes);
        }
        setSelectedNodes(nSelectedNodes);
        console.log(`currentNode = ${JSON.stringify(currentNode)}`)
      }}
    />
  )

}

export default TreeSelect;
