/* eslint-disable */
import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import {loginApi} from '../network/api';
import LoginImage from '../icons/login_image.webp';
import LogoImage from '../icons/logo.png';
import Loader from '../components/Loader';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoginForm from '../forms/LoginForm';
import useLogin from '../hooks/useLogin';

function LoginView() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [ email, setEmail ] = useState(' ');
  const [ password, setPassword] = useState(' ');
  const [loading, setLoading] = useState(false);
  const [ error, setError ] = useState(null);
  const history = useNavigate();

  const isLoggedInFromBefore = useLogin();

  function doLogin() {
    setLoading(true);
    loginApi(email, password)
      .then(() => {
        setIsLoggedIn(true);
        setLoading(false);
        history('/problems');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError('Invalid Email Or Password!');
        }
        else if (error.response.status === 502) {
          setError('Unable to reach server! Please check your internet connection');
        }
        else {
          setError('Unknown error occured while logging in!');
        }
        setLoading(false);
      });
  }

  useEffect(() => {
    if (isLoggedInFromBefore === 1) {
      history('/problems');
    }
  }, [isLoggedInFromBefore]);

  return (
    <Container fluid>
      <Row>
        <Col lg={4}>
          <Row>
            <img src={LogoImage} style={{ width: '200px', marginTop: '20px'}}/>
          </Row>
          <Row>
            <Col xs={2}>
            </Col>
            <Col xs={8}>
              <div style={{ marginTop: '50px'}}> <p style={{ fontSize: '24px', color: '#39424d', fontWeight: 800 }}> Welcome! </p> </div>
              {
                !R.isNil(error)
                && (
                  <p style={{ color: 'red'}}> { error } </p>
                )
              }
              <div className="mt-4">
                <LoginForm onSubmit={doLogin} setEmail={setEmail} setPassword={setPassword} />
                <p className="mt-2" style={{ fontSize: '14px' }}> <a href='/resetPassword'> Forgot your password ? </a> </p>
              </div>
            </Col>
            <Col xs={2}>
            </Col>
          </Row>

        </Col>
        <Col lg={8}>
          <img src={LoginImage} style={{ height: '100vh' }}/>
        </Col>
      </Row>
      <Loader loading={loading} />
    </Container>
  )
}

export default LoginView;
