import { useState, useEffect } from 'react';
import * as R from 'ramda';

function useError(sourcesOfError) {
  const [ error, setError ] = useState(undefined);
  useEffect(() => {
    for (let i = 0; i < sourcesOfError.length; i++) {
      let currentError = sourcesOfError[i];
      if (!R.isNil(currentError)) {
        setError(currentError);
        break;
      }
    }
  }, sourcesOfError);

  return error;
}

export default useError;
