/* eslint-disable */
import React from 'react';
import { css } from '@emotion/react';
import PulseLoader from 'react-spinners/PulseLoader';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const style = {
  backgroundColor: 'black',
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: '0px',
  left: '0px',
  opacity: 0.8,
};

const loaderStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  opacity: 1.0,
};

function Loader({ loading }) {
  return (
    loading && (
    <div style={style}>
      <div style={loaderStyle}>
        <PulseLoader
          css={override}
          size={15}
          color="#35b479"
          loading={loading}
        />
      </div>
    </div>
    )
  );
}

export default Loader;
