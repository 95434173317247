import { useState, useEffect } from 'react';

function useApi(dataDefaultValue, apiFunc, dependencies, ...args) {
  const [loading, setLoading ] = useState(false);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState(dataDefaultValue);

  useEffect(() => {
    setLoading(true);
    apiFunc(args)
      .then(data => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        setData(dataDefaultValue);
      })
  }, dependencies);

  return { loading, error, data };
}

export default useApi;
