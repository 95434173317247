/* eslint-disable */
import * as R from 'ramda';
import Loader from '../Loader';

function LoaderHOC({ loading, error, children }) {
  if (!R.isNil(loading) && loading === true && R.isNil(error)) {
    return (
      <Loader loading />
    );
  } else if (!R.isNil(loading) && loading === false && !R.isNil(error)) {
    return (
      <div> { error.message } </div>
    );
  } else if (!R.isNil(loading) && loading === false && R.isNil(error)) {
    return children;
  } else {
    return (
      <>
        <div> Invalid State </div>
        <div> { `${loading} and ${error}`} </div>
      </>
    );
  }
}

export default LoaderHOC;
