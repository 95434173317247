/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import * as R from 'ramda';
import Editor from '@monaco-editor/react';
import PulseLoader from 'react-spinners/PulseLoader';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import CustomNavBar from '../components/CustomNavBar';
import { fetchSingleSubmissionDetailsApi } from '../network/api';
import ErrorCodes from '../constants/errorCode';
import Loader from '../components/Loader';
import Languages from '../constants/languages';
import ReverseLanguages from '../constants/reverseLanguage';
import Verdict from '../constants/verdict';
import useInterval from '../hooks/useInterval';

const Columns = [
  {
    abc: 'hello',
    accessor: 'time',
    id: 'time',
    Header: 'Time',
  },
  {
    accessor: 'memory',
    id: 'memory',
    Header: 'Memory',
  },
  {
    accessor: 'score',
    id: 'score',
    Header: 'Score',
  },
  {
    accessor: 'verdict',
    id: 'verdict',
    Header: 'Verdict',
  },
];

function TestCasesTable({ testCases }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns: Columns,
    data: testCases,
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Table striped bordered hover {...getTableProps()}>
      <thead>
        {
        headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            { headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>
                {' '}
                { column.render('Header')}
                {' '}
              </th>
            ))}
          </tr>
        ))
      }
      </thead>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => <td {...cell.getCellProps()}>{cell.render('Cell')}</td>)}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

function SubmissionsView() {
  const { submissionId } = useParams();
  const [submission, setSubmission] = useState(null);
  const [loading, setLoading] = useState(true);
  const [shouldFetchStatus, setShouldFetchStatus] = useState(true);
  const [error, setError] = useState(null);

  // eslint-disable-next-line no-shadow
  function fetchSubmissionStatus(submissionId) {
    fetchSingleSubmissionDetailsApi(submissionId)
      // eslint-disable-next-line no-shadow
      .then((submission) => {
        setSubmission(submission);
        setLoading(false);
      })
      // eslint-disable-next-line no-shadow
      .catch((error) => {
        setLoading(false);
        console.log(`error = ${JSON.stringify(error)}`);
        if (error.response.status === 502) {
          setError('Unable to reach server! Please try again later!');
        } else if (error.response.status === 400) {
          if (error.response.data.errorCode === ErrorCodes.SUBMISSION_NOT_FOUND) {
            setError('Given submission does not exist!');
          } else {
            setError(`Bad Request: Unknown error occurred! errorCode = ${error.response.data.errorCode}`);
          }
        } else {
          setError(`Unknown error occurred! Status = ${error.response.status}`);
        }
      });
  }

  useInterval(() => {
    if (shouldFetchStatus) {
      fetchSubmissionStatus(submissionId);
      if (!R.isNil(submission) && !(submission.verdict === Verdict.SUBMITTED)
        && !(submission.verdict === Verdict.IN_QUEUE)) {
        setShouldFetchStatus(false);
      }
      setSubmission(submission);
    }
  }, 3000);

  useEffect(() => {
    setLoading(true);
    fetchSubmissionStatus(submissionId);
  }, [submissionId]);

  const testCases = R.pathOr([], ['testCases'], submission);
  let correct = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < testCases.length; i++) {
    if (testCases[i].verdict === Verdict.ACCEPTED) {
      correct += 1;
    }
  }

  return (
    <>
      <CustomNavBar />
      <Container fluid className="mt-4">
        {
          loading === false && !R.isNil(error) && (
            <div style={{ color: 'red' }}>
              {' '}
              { error }
              {' '}
            </div>
          )
        }
        {
          loading === false && !R.isNil(submission) && (
            <>
              <PulseLoader
                size={15}
                color="#35b479"
                loading={shouldFetchStatus}
              />
              <h4> Execution Results </h4>
              <h6>
                {' '}
                { `Verdict: ${submission.verdict}` }
                {' '}
              </h6>
              <h6>
                {' '}
                { `${correct} / ${testCases.length} test cases passed`}
                {' '}
              </h6>
              { testCases.length > 0
                && (
                  <>
                    <h4> Test Case Details </h4>
                    <TestCasesTable testCases={testCases} />
                  </>
                )}
              <h4> Source Code </h4>
              <Editor
                height="70vh"
                language={R.isNil(submission) ? 'javascript' : Languages[ReverseLanguages[submission.language]].editorName}
                theme="dark"
                value={R.isNil(submission) ? '// Please wait code is loading' : submission.sourceCode}
                options={{
                  readOnly: true,
                }}
              />
            </>
          )
        }
        <Loader loading={loading} />
      </Container>
    </>
  );
}

export default SubmissionsView;
