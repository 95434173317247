/* eslint-disable */
import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomNavBar from '../components/CustomNavBar';

import ProblemTable from '../components/ProblemTable';
import { fetchProblemsApi } from '../network/api';
import useLoading from '../hooks/useLoading';
import useError from '../hooks/useError';
import useApi from '../hooks/useApi';
import { fetchCategories } from '../network/problemApi';
import LoaderHOC from '../components/hoc/LoaderHOC';
import TreeSelect from '../components/TreeSelect';
import ProblemFilter from '../components/ProblemFilter';

function filterByField(arr, field, values) {
  if (values.length === 0) { return arr; }
  let result = [];
  R.forEach(item => {
    let fieldValues = item[field];
    let itemMatches = false;
    for(let i = 0; i < fieldValues.length && itemMatches === false; i++) {
      for(let j = 0; j < values.length && itemMatches === false; j++) {
        if (fieldValues[i] === values[j]) {
          itemMatches = true;
        }
      }
    }
    if (itemMatches) {
      result.push(item);
    }
  }, arr);
  return result;
}

function ProblemsView() {
  const [problemsApi] = useState({ dependencies: [] });
  const [categoriesApi] = useState({ dependencies: [] });
  const problems  = useApi([], fetchProblemsApi, problemsApi.dependencies,
    []);
  const categories = useApi([], fetchCategories, categoriesApi.dependencies, [false])
  const loading = useLoading([problems.loading, categories.loading]);
  const error = useError([problems.error, categories.error]);

  const [difficultyFilter, setDifficultyFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [filteredProblems, setFilteredProblems] = useState([]);

  useEffect(() => {
    let filteredProblems = problems.data;
    let difficultyFilters = difficultyFilter.map(filter => filter.value);
    let categoryFilters = categoryFilter.map(filter => filter.value);
    filteredProblems = filterByField(filteredProblems, 'difficulty', difficultyFilters)
    filteredProblems = filterByField(filteredProblems, 'categories', categoryFilters);
    setFilteredProblems(filteredProblems);
  }, [problems.data, difficultyFilter, categoryFilter]);

  return (
    <React.Fragment>
      <CustomNavBar />
        <Container fluid className="mt-4 mr-4">
          <Row>
            <Col xs={3}>
              <LoaderHOC
                loading={categories.loading}
                error={categories.error}
              >
                <ProblemFilter
                  categories={categories.data}
                  onSelectDifficulty={(difficulty) => setDifficultyFilter(difficulty)}
                  onSelectCategory={(categories) => setCategoryFilter(categories)}
                />
              </LoaderHOC>
            </Col>
            <Col xs={9}>
              <LoaderHOC
                loading={problems.loading}
                error={problems.error}
              >
                {
                  filteredProblems.length === 0
                  && (
                    <div> Sorry! No problems have been uploaded so far! </div>
                  )
                }
                {
                  problems.data.length > 0
                  && (
                    <ProblemTable
                      problems={filteredProblems}
                    />
                  )
                }
              </LoaderHOC>
            </Col>
          </Row>
        </Container>
    </React.Fragment>
  );
}

export default ProblemsView;
