/* eslint-disable */
import * as R from 'ramda';
import React, { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import useApi from '../hooks/useApi';
import { addNewProblem, fetchCategories } from '../network/problemApi';
import LoaderHOC from '../components/hoc/LoaderHOC';
import useAppState from '../hooks/useAppState';

function ProblemAddForm() {
  const formik = useFormik({
    initialValues: {
      problemId: '',
      title: '',
      problemLink: ''
    },
    validationSchema: Yup.object({
      problemId: Yup.string().required('Please fill problem ID before logging in!'),
      title: Yup.string().required('Please enter problem title before logging in!'),
      problemLink: Yup.string().required('Please enter problem link before logging in!')
    }),
    onSubmit: (values) => {
      const { problemId, title, problemLink, difficulty, courses, categories }
      = values;
      setApi({ loading: true, error: undefined });
      addNewProblem(problemId, title, problemLink, difficulty, courses, categories)
        .then(() => {
          setApi({ loading: false, error: undefined });
        })
        .then((error) => {
          setApi({ loading: false, error });
        });
    },
  });

  const problemIdRef = useRef();
  const titleRef = useRef();
  const problemLinkRef = useRef();
  const difficultyRef = useRef();
  const courseRef = useRef();
  const categoriesRef = useRef();

  const [dependencies, setDependencies] = useState({ categories: [], submit: [] });
  const categories = useApi([], fetchCategories, dependencies.categories, [true]);

  const [api, setApi] = useState({ loading: false, error: undefined });
  const [component, setComponent] = useState({ loading: false, error: undefined });

  useEffect(() => {
    let sourcesOfLoading = [categories.loading, api.loading];
    let finalLoading = false;
    for(let i = 0; i < sourcesOfLoading.length; i++) {
      let loading = sourcesOfLoading[i];
      finalLoading = finalLoading || loading;
    }
    setComponent({...component, loading: finalLoading });
  }, [categories.loading, api.loading]);

  useEffect(() => {
    let sourcesOfError = [categories.error, api.error];
    for(let i = 0; i < sourcesOfError.length; i++) {
      let error = sourcesOfError[i];
      if (!R.isNil(error)) {
        setComponent({ ...component, error });
      }
    }
  }, [categories.error, api.error]);

  return (
    <LoaderHOC
      loading={component.loading}
      error={component.error}
    >
      <Form className="mt-4" onSubmit={formik.handleSubmit}>
        <Form.Group controlId="formAddProblem">
          <Form.Label>Problem ID</Form.Label>
          <Form.Control
            name="problemId"
            type="text"
            placeholder="Enter problem ID"
            value={formik.values.problemId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isValid={R.isNil(formik.errors.problemId) && formik.touched.problemId === true}
            ref={problemIdRef}
          />
        </Form.Group>
        <Form.Group controlId="formBasicTitle">
          <Form.Label>Problem Title</Form.Label>
          <Form.Control
            name="title"
            type="text"
            placeholder="Enter Problem Title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isValid={R.isNil(formik.errors.title) && formik.touched.title === true}
            ref={titleRef}
          />
        </Form.Group>
        <Form.Group controlId="formBasicLink">
          <Form.Label>Problem Link</Form.Label>
          <Form.Control
            name="problemLink"
            type="text"
            placeholder="Enter Problem Link"
            value={formik.values.problemLink}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isValid={R.isNil(formik.errors.problemLink) && formik.touched.problemLink === true}
            ref={problemLinkRef}
          />
        </Form.Group>
        <Form.Group controlId="formBasicDifficulty">
          <Form.Label>Problem Difficulty</Form.Label>
          <Form.Select
            multiple
            name="difficulty"
            type="select"
            value={formik.values.difficulty}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isValid={R.isNil(formik.errors.difficulty) && formik.touched.difficulty === true}
            ref={difficultyRef}
          >
            <option value={"Cakewalk"}> Cakewalk </option>
            <option value={"Easy"}> Easy </option>
            <option value={"Medium"}> Medium </option>
            <option value={"Hard"}> Hard </option>
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicCourses">
          <Form.Label>Courses</Form.Label>
          <Form.Select
            multiple
            name="courses"
            type="select"
            value={formik.values.courses}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isValid={R.isNil(formik.errors.courses) && formik.touched.courses === true}
            ref={courseRef}
          >
            <option value={"MERN Full Stack Bootcamp"}> MERN Full Stack Bootcamp</option>
            <option value={"Java Full Stack Bootcamp"}> Java Full Stack Bootcamp</option>
            <option value={"Live Interview Prep Bootcamp"}>Live Interview Prep Bootcamp</option>
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="formBasicCategory">
          <Form.Label>Problem Category</Form.Label>
          <Form.Select
            multiple
            name="categories"
            type="select"
            value={formik.values.categories}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isValid={R.isNil(formik.errors.categories) && formik.touched.categories === true}
            ref={categoriesRef}
          >
            {
              categories.data.map((category, i) => <option key={i} value={category.value}> { category.name } </option> )
            }
          </Form.Select>
        </Form.Group>
        <Button variant="primary" type="submit">
          Add Problem
        </Button>
      </Form>
    </LoaderHOC>
  );
}

export default ProblemAddForm;
