/* eslint-disable */
import React from 'react';
import * as R from 'ramda';
import MDReactComponent from 'markdown-react-js';
import Loader from './Loader';
import useProblem from '../hooks/useProblem';

function ProblemStatement(props) {
  let { loadingProblem, problemData, problemError, problemDescription } = useProblem(props.problemId);
  return (
    <React.Fragment>
      <Loader loading={loadingProblem} />
      {
        R.isNil(problemError) && loadingProblem === false
        && (
          <div className="mt-4">
            <h5> <b> {problemData.title} </b> </h5>
            <MDReactComponent text={problemDescription} />
          </div>
        )
      }
      {
        !R.isNil(problemError) && loadingProblem === false
        && (
          <div className="mt-4" style={{ color: 'red'}}> { problemError } </div>
        )
      }
    </React.Fragment>
  );
}

export default ProblemStatement;
