const axios = require('axios');
const ApiConstants = require('./apiConstants');
const { baseUrl } = require('./apiConstants');

function fetchCategories(...args) {
  const { token, role } = ApiConstants.fetchTokenAndRole();
  const baseUrl = ApiConstants.baseUrl;

  return axios.get(`${baseUrl}/problems/categories?flatten=${args[0]}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => {
      return response.data;
    });
}

function addNewProblem(problemId, title, problemLink, difficulty, courses, categories) {
  const { token } = ApiConstants.fetchTokenAndRole();
  return axios.post(`${baseUrl}/problems`, {
    problemId,
    title,
    problemLink,
    difficulty,
    courses,
    categories
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => response.data);
}

module.exports = {
  fetchCategories,
  addNewProblem
};


