import Languages from './languages';

const DefaultCode = {
  [Languages.JS.editorName]: '// Your Code Goes Here',
  [Languages.CPP.editorName]: `#include <iostream>
using namespace std;
int main() {
  \t// your code goes here
  \treturn 0;
}`,
  [Languages.PYTHON3.editorName]: '# your code goes here',
  [Languages.PYTHON2.editorName]: '# your code goes here',
  [Languages.JAVA.editorName]: `/* package whatever; // don't place package name! */
import java.util.*;
import java.lang.*;
import java.io.*;

/* Name of the class has to be "Main" only if the class is public. */
class PrepForTech
{
\tpublic static void main (String[] args) throws java.lang.Exception
\t{
\t\t// your code goes here
\t}
}`,
};

export default DefaultCode;
