/* eslint-disable */
const axios = require('axios');
const baseUrl = require('./apiConstants').baseUrl;

function isLoggedInApi() {
  const token = window.localStorage.getItem('token');
  return axios.get(`${baseUrl}/auth/isLoggedIn`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then((response) => {
      console.log(`response = ${JSON.stringify(response)}`);
    });
}

function loginApi(email, password) {
  return axios.post(`${baseUrl}/auth/login?email=${email}&password=${password}`)
    .then((response) => {
      window.localStorage.setItem('token', response.data.token)
      window.localStorage.setItem('role', response.data.role);
    });
}

function fetchProblemDetailsApi(problemId) {
  const token = window.localStorage.getItem('token');
  return axios.get(`${baseUrl}/problems/${problemId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => response.data);
}

function submitSolutionApi(problemId, language, sourceCode) {
  const token = window.localStorage.getItem('token');
  return axios.post(`${baseUrl}/submissions`,
    {
      sourceCode,
      problemId,
      compilerId: language,
      language: language
    },
    {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }});
}

function fetchSingleSubmissionDetailsApi(submissionId) {
  const token = window.localStorage.getItem('token');
  return axios.get(`${baseUrl}/submissions/${submissionId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(response => response.data);
}

function fetchAllProblemSubmissionsApi(problemId) {
  const token = window.localStorage.getItem('token');
  return axios.get(
    `${baseUrl}/submissions/problem/${problemId}`,
    { headers: {
        Authorization: `Bearer ${token}`
    }}
  ).then(response => response.data);
}

function fetchProblemsApi() {
  const token = window.localStorage.getItem('token');
  return axios({
      method: 'GET',
      url: `${baseUrl}/problems`,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => response.data);
}

function fetchLogoutApi() {
  return axios({
    method: 'POST',
    url: `${baseUrl}/logout`,
    withCredentials: true
  });
}

function autoSaveCodeApi(problemId, code, language) {
  return axios({
    method: 'POST',
    url: `${baseUrl}/problem/code`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      problemId,
      code,
      language
    }
  });
}

function fetchAutoSavedCodeApi(problemId, language) {
  return axios({
    method: 'GET',
    url: `${baseUrl}/problem/code?problemId=${problemId}&language=${language}`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    },
  });
}

function fetchQuizApi() {
  return axios({
    method: 'GET',
    url: `${baseUrl}/quiz`,
    headers: {
      'Content-Type': 'application/json'
    },
  });
}

function fetchFromS3(url) {
  return axios({
    method: 'GET',
    url,
    responseType: 'arraybuffer'
  })
    .then((response) => {
      const buffer = new Buffer(response.data, 'binary');
      const textdata = buffer.toString(); // for string
      console.log(`textData = ${textdata}`);
      return textdata;
    });
}

function testSolutionApi(language, sourceCode, inputFile, outputFile) {
  const formData = new FormData();
  formData.append('language', language);
  formData.append('sourceCode', sourceCode);
  formData.append('inputFile', inputFile.files[0]);
  formData.append('outputFile', outputFile.files[0]);
  return axios({
    method: 'POST',
    url: `${baseUrl}/problem/test`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: formData,
  });
}

function uploadProblemApi(problemId, title, inputFile, outputFile, testCases, problemStatement) {
  const formData = new FormData();
  formData.append('problemId', problemId);
  formData.append('title', title);
  for(let i =  0; i < inputFile.length; i++) {
    formData.append(`inputFile`, inputFile[i]);
  }
  for(let i =  0; i < outputFile.length; i++) {
    formData.append(`outputFile`, outputFile[i]);
  }
  formData.append('testCases', testCases);
  formData.append('problemStatement', problemStatement);

  return axios({
    method: 'POST',
    url: `${baseUrl}/problem/upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });

}

function checkProblemIdApi(problemId) {
  return axios({
    method: 'GET',
    url: `${baseUrl}/problem/problemId?problemId=${problemId}`,
  });
}


export {
  isLoggedInApi,
  loginApi,
  fetchProblemDetailsApi,
  fetchAllProblemSubmissionsApi,
  submitSolutionApi,
  fetchProblemsApi,
  fetchSingleSubmissionDetailsApi,
  fetchLogoutApi,
  autoSaveCodeApi,
  fetchAutoSavedCodeApi,
  fetchQuizApi,
  fetchFromS3,
  testSolutionApi,
  checkProblemIdApi,
  uploadProblemApi,
};
