const localUrl = 'http://localhost:8081';
const devUrl = 'https://b2a1mc905d.execute-api.ap-south-1.amazonaws.com/dev';

const baseUrl = devUrl;

function fetchTokenAndRole() {
  const token = window.localStorage.getItem('token');
  const role = window.localStorage.getItem('role');

  return {
    token,
    role
  };
}

module.exports = {
  baseUrl,
  fetchTokenAndRole
};
