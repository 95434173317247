/* eslint-disable */
import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import Editor from '@monaco-editor/react';
import Languages from '../constants/languages';
import DefaultCode from '../constants/defaultCode';
import Form from 'react-bootstrap/Form';

function CodeEditor({ setEditorRef, selectBackendLanguage, autoSavedCode, setEditorCurrentCode }) {
  const [language, setLanguage] = useState(Languages.CPP.editorName);
  const [backendLanguage, setBackendLanguage] = useState(Languages.CPP.backendName);
  const [currentCode, setCurrentCode] = useState(DefaultCode[language]);

  function handleEditorDidMount(editor) {
    setEditorRef(editor);
  }

  function onLanguageChange(e) {
    console.log(`e.target.value = ${e.target.value}`);
    setLanguage(Languages[e.target.value].editorName);
    setBackendLanguage(Languages[e.target.value].backendName);
    selectBackendLanguage(Languages[e.target.value].backendName);
  }

  useEffect(() => {
    setCurrentCode(autoSavedCode);
  }, [autoSavedCode]);

  useEffect(() => {
    console.log(`Running the hook!`);
    console.log(`currentCode = ${currentCode}`);
    setEditorCurrentCode(currentCode);
  }, [currentCode]);

  console.log(`autoSavedCode = ${JSON.stringify(autoSavedCode)}`);

  return (
    <React.Fragment>
      <Form>
        <Form.Group>
          <Form.Label> Programming Language </Form.Label>
          <Form.Control as="select" onChange={(e) => onLanguageChange(e)}>
            <option value="CPP"> C++ </option>
            <option value="JAVA"> Java11  </option>
            <option value="PYTHON2"> Python 2 </option>
            <option value="PYTHON3"> Python 3 </option>
            <option value="JS"> Javascript </option>
          </Form.Control>
        </Form.Group>
      </Form>
      <Editor
          height="70vh"
          language={language}
          theme="dark"
          value={R.isNil(currentCode) ? '' : currentCode}
          onChange={(newValue) => setCurrentCode(newValue)}
          onMount={handleEditorDidMount}
      />
    </React.Fragment>
  )
}

export default CodeEditor;
