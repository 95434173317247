const Languages = {
  JS: {
    editorName: 'javascript',
    actualName: 'Javascript',
    backendName: 'javascript',
  },
  CPP: {
    editorName: 'cpp',
    actualName: 'C++14',
    backendName: 'CPP',
  },
  JAVA: {
    editorName: 'java',
    actualName: 'Java 11',
    backendName: 'java',
  },
  PYTHON2: {
    editorName: 'python',
    actualName: 'python2',
    backendName: 'python2',
  },
  PYTHON3: {
    editorName: 'python',
    actualName: 'python3',
    backendName: 'python3',
  },
};

export default Languages;
