/* eslint-disable */
import React from 'react';
import Modal from 'react-bootstrap/Modal';

function LoadingModal({ size, color, loading }) {
  return (
    <Modal
      show={loading}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          Submitting... Please Wait
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      </Modal.Body>
    </Modal>
  )
}

export default LoadingModal;
