/* eslint-disable */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Logo from '../icons/prepfortech-beta-logo.png';
import useLogin from '../hooks/useLogin';
import { fetchLogoutApi } from '../network/api';

const NavStyle = {
  color: 'black',
  fontSize: '18px',
  marginRight: '10px',
};

const NavBarStyle = {
  height: '80px',
};

function CustomNavBar() {
  const isLoggedIn = useLogin();
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  function logout() {
    window.localStorage.clear()
    navigate('/login');
  }

  return (
    isLoggedIn && (
      <Navbar expand="lg" style={NavBarStyle} className="shadow p-3 mb-5 bg-white rounded">
        <Navbar.Brand href="/">
          <img
            src={Logo}
            width="180"
            className="d-inline-block align-top"
            alt="PrepForTech Logo"
          />
        </Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link style={NavStyle} href="/problems"> Problems </Nav.Link>
        </Nav>
        <Nav className="ml-auto mr-4">
          {
            error && (
              <div style={{ color: 'red' }}>
                {' '}
                { error }
                {' '}
              </div>
            )
          }
          <Button style={{ float: 'right' }} variant="outline-dark" onClick={() => logout()}>
            Logout
          </Button>
        </Nav>
      </Navbar>
    )
  );
}

export default CustomNavBar;
