import { useState, useEffect } from 'react';
import * as R from 'ramda';

function useLoading(sourcesOfLoading) {
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    let finalLoading = false;
    console.log(`sourcesOfLoading = ${JSON.stringify(sourcesOfLoading)}`);
    for (let i = 0; i < sourcesOfLoading.length; i++) {
      let loading = sourcesOfLoading[i];
      finalLoading = finalLoading || loading;
    }
    setLoading(finalLoading);
  }, sourcesOfLoading);

  return loading;
}

export default useLoading;

