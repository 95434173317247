/* eslint-disable */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProblemAddForm from '../forms/ProblemAddForm';

function ProblemAddView() {
  return (
    <Container fluid>
      <Row>
        <Col xs={6}>
          <ProblemAddForm />
        </Col>
      </Row>
    </Container>
  );
}

export default ProblemAddView;
