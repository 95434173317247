/* eslint-disable */
import React, { useRef, useEffect } from 'react';
import * as R from 'ramda';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function SendOtpForm({ onSubmit, setEmail }) {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Please fill Email before logging in!'),
    }),
    onSubmit: (values) => onSubmit(values),
  });

  const emailRef = useRef();

  useEffect(() => {
    setEmail(formik.values.email);
  }, [formik.values.email]);

  return (
    <Form className="mt-4" onSubmit={formik.handleSubmit}>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          name="email"
          type="email"
          placeholder="Enter email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isValid={R.isNil(formik.errors.email) && formik.touched.email === true}
          ref={emailRef}
        />
      </Form.Group>
      <br />
      <Button variant="primary" type="submit">
        Send OTP
      </Button>
    </Form>
  );
}

export default SendOtpForm;
