/* eslint-disable */
import React from 'react';
import * as R from 'ramda';
import Table from 'react-bootstrap/Table'
import { useTable } from 'react-table';
import UnsolvedIcon from '../icons/x-circle.svg';
import SolvedIcon from '../icons/check-circle.svg';
import UnattemptedIcon from '../icons/help-circle.svg';
import Verdict from '../constants/verdict';
import ProblemStatus from '../constants/problemStatus';

const columns = [
  {
    accessor: 'problemStatus',
    id: 'status',
    Header: 'Status',
    disableFilters: false,
    Cell: ({ value, row }) => {
      if (row.original.problemStatus.problemStatus === ProblemStatus.SUCCESS) {
        return <img src={SolvedIcon} />;
      }
      else if (row.original.problemStatus.problemStatus === ProblemStatus.FAILED) {
        return <img src={UnsolvedIcon} />
      }
      else {
        return <img src={UnattemptedIcon} />;
      }
    }
  },
  {
    accessor: 'title',
    id: 'title',
    Header: 'Name',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value, row }) => (
      // eslint-disable-next-line react/prop-types
      <a href={`https://2f7d67b9.widgets.sphere-engine.com/lp?hash=${row.original.widgetCode}`}>
        { value }
      </a>
    ),
  },
  {
    accessor: 'difficulty',
    id: 'difficulty',
    Header: 'Difficulty',
  },
  {
    accessor: (original) => R.join(',', original.categories),
    id: 'category',
    Header: 'Category',
  }
];


function ProblemTable({ problems }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: problems,
    },
  );

  return (
    <>
      <Table striped bordered hover { ...getTableProps()} >
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          { rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default ProblemTable;
