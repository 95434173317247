/* eslint-disable */
import React, { useState } from 'react';
import * as R from 'ramda';
import SendOtpForm from '../forms/SendOtpForm';
import ResetPasswordForm from '../forms/ResetPasswordForm';
import Container from 'react-bootstrap/Container';
import Loader from '../components/Loader';
import { sendOtpApi, resetPasswordApi } from '../network/authApi';

const Screens = {
  SEND_OTP_SCREEN: 'SEND_OTP_SCREEN',
  RESET_PASSWORD_SCREEN: 'RESET_PASSWORD_SCREEN',
  SUCCESS_SCREEN: 'SUCCESS_SCREEN',
};

function ResetPasswordView() {
  const [screen, setScreen] = useState(Screens.SEND_OTP_SCREEN);
  const [email, setEmail] = useState(null);
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function sendOtp() {
    setLoading(true);
    sendOtpApi(email)
      .then(() => {
        setLoading(false);
        setError(null);
        setScreen(Screens.RESET_PASSWORD_SCREEN);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 502) {
          setError('Unable to reach server');
        }
        else if (error.response.status === 400) {
          if (error.response.data.errorCode === 'UNABLE_TO_SEND_EMAIL') {
            setError('Error occurred while sending email. Please try again!');
          }
          else if (error.response.data.errorCode === 'INVALID_EMAIL') {
            setError('Given email does not exist in our records!');
          }
          else {
            setError(`Unknown error occurred while sending OTP ${error.response.data.errorCode}`);
          }
        }
        else {
          setError(`Unknown error occurred ${error.response.status}`);
        }
      })
  }

  function resetPassword() {
    setLoading(true);
    resetPasswordApi(email, password, otp)
      .then(() => {
        setError(null);
        setLoading(false);
        setScreen(Screens.SUCCESS_SCREEN);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 502) {
          setError('Unable to reach server');
        }
        else if (error.response.status === 400) {
          if (error.response.data.errorCode === 'OTP_NOT_FOUND' || error.response.data.errorCode === 'OTP_ALREADY_USED') {
            setError('Invalid OTP');
          }
          else {
            setError(`Unknown error occurred while sending OTP ${error.response.data.errorCode}`);
          }
        }
        else {
          setError(`Unknown error occurred ${error.response.status}`);
        }
      })
  }


  return (
    <Container>
      <h3 className="mt-4"> Reset Password </h3>
      {
        loading === true && <Loader loading={loading} />
      }
      {
        !R.isNil(error) &&
          <div style={{ color: 'red'}}> { error } </div>
      }
      {
        screen === Screens.SEND_OTP_SCREEN
      && (
          <SendOtpForm setEmail={setEmail} onSubmit={sendOtp}/>
      )
      }
      {
        screen === Screens.RESET_PASSWORD_SCREEN
        && (
            <>
              <div> Please enter the one time password you received on your email </div>
              <ResetPasswordForm
                setOtp={setOtp}
                setPassword={setPassword}
                onSubmit={resetPassword}
              />
            </>
        )
      }
      {
        screen === Screens.SUCCESS_SCREEN
        && (
          <>
            <div style={{ color: 'green' }}> Password reset successfully </div>
            <div> Please click here to go to <a href="/login"> login page </a> </div>
          </>
        )
      }
    </ Container>
  )
}

export default ResetPasswordView;
