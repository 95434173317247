/* eslint-disable */
import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import TreeSelect from './TreeSelect';
import ProblemStatus from '../constants/problemStatus';

const difficultyLevels = ['Cakewalk', 'Easy', 'Medium', 'Hard']
  .map(level => ({ label: level, name: level }));
const problemStatus = R.map(key => ({ name: ProblemStatus[key], label: ProblemStatus[key] }),
  R.keys(ProblemStatus));

/** Recursively flattens the categories */
// function flattenCategory(category) {
//   let result = [];
//   result.push({ name: category.name });
//   if (!R.isNil(category.children)) {
//     for(let i = 0; i < category.children.length; i++) {
//       let child = category.children[i];
//       let flattenedChild = flattenCategory(child);
//       result = R.concat(result, flattenedChild);
//     }
//   }
//   return result;
// }
//
// function getCategoryByName(categoryName, categories) {
//   return R.find(category => category.name === categoryName, categories);
// }
//
// function selectCategory(categoryName, allCategories) {
//   let category = getCategoryByName(categoryName, allCategories);
//   console.log(`selecting category ${categoryName}`);
//   return flattenCategory(category);
// }
//
// function selectCategories(categories, allCategories, categoryFilter) {
//   let selectedCategories = [];
//   R.forEach(category => {
//     selectedCategories = R.concat(selectedCategories, selectCategory(category.name, allCategories, categoryFilter));
//   }, categories);
//   return selectedCategories;
// }




function ProblemFilter({ categories, onSelectDifficulty, onSelectCategory }) {
  const [difficultyFilter, setDifficultyFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);

  useEffect(() => {
    onSelectDifficulty(difficultyFilter);
  }, [difficultyFilter]);

  useEffect(() => {
    console.log(`categoryFilter = ${JSON.stringify(categoryFilter)}`);
    onSelectCategory(categoryFilter);
  }, [categoryFilter]);

  return (
    <>
      <h3> Filter By Problem Category </h3>
      <TreeSelect
        data={categories}
        onChange={(selectedNodes) => {
          setCategoryFilter(selectedNodes);
          // setCategoryFilter(selectCategories(selectedNodes, categories, []));
        }}
      />
      <br />
      <h3> Filter By Problem Difficulty Level </h3>
      <TreeSelect
        data={difficultyLevels}
        onChange={(selectedNodes) => {
          setDifficultyFilter(selectedNodes);
        }}
      />
      {/*<h3> Filter By Solved Status </h3>*/}
      {/*<TreeSelect*/}
      {/*  data={problemStatus}*/}
      {/*/>*/}
    </>
  )
}

export default ProblemFilter;
