const ErrorCodes = {
  UNABLE_TO_SEND_EMAIL: 'UNABLE_TO_SEND_EMAIL',
  INVALID_EMAIL: 'INVALID_EMAIL',
  OTP_ALREADY_USED: 'OTP_ALREADY_USED',
  OTP_NOT_FOUND: 'OTP_NOT_FOUND',
  PROBLEM_NOT_FOUND: 'PROBLEM_NOT_FOUND',
  SUBMISSION_NOT_FOUND: 'SUBMISSION_NOT_FOUND',
  API_REQUEST_FAILED: 'API_REQUEST_FAILED',
  INVALID_EMAIL_PASSWORD: 'INVALID_EMAIL_PASSWORD',
  UNAUTHORIZED: 'UNAUTHORIZED',
  OUTPUT_FILE_TOO_LARGE: 'OUTPUT_FILE_TOO_LARGE',
  TOTAL_FILE_TOO_LARGE: 'TOTAL_FILE_TOO_LARGE',
  PROBLEM_ID_EXISTS: 'PROBLEM_ID_EXISTS',
};

export default ErrorCodes;
