const axios = require('axios');
const ApiConstants = require('./apiConstants');
const baseUrl = ApiConstants.baseUrl;

function sendOtpApi(email) {
  return axios({
    method: 'POST',
    url: `${baseUrl}/auth/otp`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      email
    }
  });
}

function resetPasswordApi(email, password, otp) {
  return axios({
    method: 'POST',
    url: `${baseUrl}/auth/password`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      email,
      password,
      otp
    }
  });
}

module.exports = {
  sendOtpApi,
  resetPasswordApi
};
