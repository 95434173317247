/* eslint-disable */
import { useParams } from 'react-router-dom';
function WidgetView() {
  const { widgetCode } = useParams();
  return (
    <div style={{ height: '100vh' }} className='se-widget' data-widget={widgetCode}> </div>
  )
}

export default WidgetView;
