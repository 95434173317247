const ReverseLanguage = {
  javascript: 'JS',
  CPP: 'CPP',
  java: 'JAVA',
  python2: 'PYTHON2',
  python3: 'PYTHON3',
  CSharp: 'CSharp',
};

export default ReverseLanguage;
