/* eslint-disable */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ErrorModal({ show, message, onClose }) {
  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          { message }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onClose()}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ErrorModal;
