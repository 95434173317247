/* eslint-disable */
import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function BottomNavBar({ onSubmit, onCodeSave }) {
  return (
    <Navbar fixed="bottom">
      <Nav className="ml-auto">
      <Form inline>
        <Button variant="primary" className="mr-4" onClick={() => onCodeSave()}> Save </Button>
        <Button variant="success" className="mr-4" onClick={() => onSubmit()}> Submit </Button>
      </Form>
      </Nav>
    </Navbar>
  )
}

export default BottomNavBar;
