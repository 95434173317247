import { useState, useEffect } from 'react';
import { isLoggedInApi } from '../network/api';

/**
 * Hook used to return if a user is logged in or not, 0 means not computed, 1 means logged in, 2 means not logged in
 * @returns {number}
 */
function useLogin() {
  const [isLoggedIn, setIsLoggedIn] = useState(0);

  useEffect(() => {
    isLoggedInApi()
      .then(() => {
        setIsLoggedIn(1);
      })
      .catch(() => {
        setIsLoggedIn(2);
      });
  }, []);
  return isLoggedIn;
}

export default useLogin;
