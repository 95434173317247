import { useState, useEffect } from 'react';
import { fetchProblemDetailsApi } from '../network/api';
import ErrorCodes from '../constants/errorCode';

function useProblem(problemId) {
  const [loadingProblem, setLoadingProblem] = useState(true);
  const [problemData, setProblemData] = useState({ });
  const [problemError, setProblemError] = useState(null);
  const [problemDescription, setProblemDescription] = useState(null);

  useEffect(() => {
    fetchProblemDetailsApi(problemId)
      .then((problem) => {
        setProblemData(problem);
        setProblemDescription(problem.body);
        setLoadingProblem(false);
      })
      .catch((error) => {
        console.log(`error = ${JSON.stringify(error)}`);
        if (error.response.status === 502) {
          setProblemError('Unable to contact server!');
        }
        else if (error.response.status === 400) {
          if (error.response.data.errorCode === ErrorCodes.PROBLEM_NOT_FOUND) {
            setProblemError('Given problem does not exist or is not accessible!');
          }
          else {
            setProblemError(`Bad Request: Error Code ${error.response.data.errorCode}`);
          }
        }
        else {
          setProblemError(`Unknown Error Occurred, statusCode = ${error.response.status}`);
        }
        setLoadingProblem(false);
      });
  }, [problemId]);

  return { loadingProblem, problemData, problemError, problemDescription };
}

export default useProblem;


