/* eslint-disable */
import React, { useRef, useEffect } from 'react';
import * as R from 'ramda';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function ResetPasswordForm({ onSubmit, setPassword, setOtp }) {
  const formik = useFormik({
    initialValues: {
      password: '',
      otp: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Please enter password'),
      otp: Yup.string().required('Please enter otp')
    }),
    onSubmit: (values) => onSubmit(values)
  });

  const passwordRef = useRef();
  const otpRef = useRef();

  useEffect(() => {
    setOtp(formik.values.otp);
  }, [formik.values.otp]);

  useEffect(() => {
    setPassword(formik.values.password);
  }, [formik.values.password])

  return (
    <Form className="mt-4" onSubmit={formik.handleSubmit}>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>One Time Password</Form.Label>
        <Form.Control
          name="otp"
          type="text"
          placeholder="Enter OTP"
          value={formik.values.otp}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isValid={R.isNil(formik.errors.otp) && formik.touched.otp === true}
          ref={otpRef}
        />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>
      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          name="password"
          type="password"
          placeholder="Password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isValid={R.isNil(formik.errors.password) && formik.touched.password === true}
          ref={passwordRef}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Reset Password
      </Button>
    </Form>
  );
}

export default ResetPasswordForm;
