/* eslint-disable */
import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import * as Sentry from "@sentry/react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import FallbackView from './views/FallbackView';
import ProblemsView from './views/ProblemsView';
import SubmissionsView from './views/SubmissionsView';
import ProblemSolveView from './views/ProblemSolveView';
import LoginView from './views/LoginView';
import PrivateRoute from './components/PrivateRoute';
import ResetPasswordView from './views/ResetPasswordView';
import ProblemAddView from './views/ProblemAddView';
import WidgetView from './views/WidgetView';

function App() {
  return (
    <Sentry.ErrorBoundary showDialog fallback={<FallbackView />}>
      <Router>
        <Routes>
          <Route exact path = "/" element={<LoginView/>} />
          <Route exact path = "/problems" element={<PrivateRoute><ProblemsView /></PrivateRoute>} />
          <Route exact path = "/login" element={<LoginView/>} />
          <Route path="/problems/:problemId"
          element={<PrivateRoute> <ProblemSolveView/> </PrivateRoute>} />
          <Route path = "/submissions/:submissionId" element={<PrivateRoute><SubmissionsView/></PrivateRoute>} />
          <Route path = "/resetPassword" element={<ResetPasswordView/>} />
          <Route path = "/add/problems" element={<ProblemAddView />} />
          <Route path = "/problem/:widgetCode" element={<PrivateRoute> <WidgetView /> </PrivateRoute>} />
        </Routes>
      </Router>
    </Sentry.ErrorBoundary>
  );
}

export default App;
