/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import * as R from 'ramda';
import { useNavigate, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CodeEditor from '../components/CodeEditor';
import CustomNavBar from '../components/CustomNavBar';
import ProblemStatement from '../components/ProblemStatement';
import Languages from '../constants/languages';
import { autoSaveCodeApi, fetchAutoSavedCodeApi, submitSolutionApi } from '../network/api';
import LoadingModal from '../components/LoadingModal';
import BottomNavBar from '../components/BottomNavBar';
import Loader from '../components/Loader';
import ProblemSubmissions from '../components/ProblemSubmissions';
import ErrorCodes from '../constants/errorCode';
import ErrorModal from '../components/ErrorModal';

const TabKeys = {
  DESCRIPTION: 'description',
  SUBMISSIONS: 'submissions',
};

function ProblemSolveView() {
  const [selectedTab, setSelectedTab] = useState(TabKeys.DESCRIPTION);
  const [loading, setLoading] = useState(false);
  const [autoSave, setAutoSave] = useState(false);
  const [autoSavedCode, setAutoSavedCode] = useState('');
  const [notification, setNotification] = useState(null);
  const [error, setError] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(Languages.CPP.backendName);
  const [currentCode, setCurrentCode] = useState('');
  const { problemId } = useParams();
  const history = useNavigate();

  const editorRef = useRef();

  function setEditorRef(ref) {
    editorRef.current = ref;
  }

  function setLanguage(language) {
    setSelectedLanguage(language);
  }

  function handleTabChange(tabKey) {
    setSelectedTab(tabKey);
  }

  function onCodeSubmit() {
    const sourceCode = currentCode;
    setLoading(true);
    submitSolutionApi(problemId, selectedLanguage, sourceCode)
      .then((response) => {
        const submissionId = response.data;
        setLoading(false);
        history(`/submissions/${submissionId}`);
      })
      .catch((error) => {
        console.log(`error = ${error}`);
        setLoading(false);
        if (error.response.status === 502) {
          setError('Unable to reach servers! Please try again later');
        } else if (error.response.status === 400) {
          if (error.response.data.errorCode === ErrorCodes.PROBLEM_NOT_FOUND) {
            setError('Given problem does not exist or you are not allowed to submit for this problem');
          } else {
            setError(`Bad Request: ErrorCode = ${error.response.data.errorCode}`);
          }
        } else {
          setError(`Unknown error occurred! Status = ${error.response.status}`);
        }
      });
  }

  function loadAutoSavedCode() {
    setAutoSave(true);
    fetchAutoSavedCodeApi(problemId, selectedLanguage)
      .then((resp) => {
        setAutoSave(false);
        setAutoSavedCode(resp.data.code);
      })
      .catch((error) => {
        setAutoSave(false);
        if (error.response.status === 502) {
          setError('Unable to reach servers!');
        } else {
          setError('Unknown error occured while reaching servers!');
        }
      });
  }

  useEffect(() => {
    setTimeout(() => loadAutoSavedCode(), 500);
  }, [selectedLanguage]);

  function onCodeSave() {
    setAutoSave(true);
    const sourceCode = editorRef.current;
    autoSaveCodeApi(problemId, sourceCode, selectedLanguage)
      .then(() => {
        setAutoSave(false);
        setNotification('Code Saved');
        setError(null);
      })
      .catch((error) => {
        setNotification(null);
        if (error.response.status === 502) {
          setError('Unable to reach server');
        }
        else {
          setError(`Unknown error occured while autosaving! status = ${error.response.status}`);
        }
      });
  }

  return (
    <React.Fragment>
      <CustomNavBar/>
      <Container fluid className="mt-4">
        <Row>
          <Col>
          </Col>
          <Col>
            { !R.isNil(notification) && <div style={{ color: 'green' }}> { notification } </div> }
            { !R.isNil(error) && <div> { error } </div> }
          </Col>
        </Row>
        <Row>
          <Col>
            <Nav
              variant="tabs"
              activeKey={selectedTab}
              onSelect={(selectedKey) => handleTabChange(selectedKey)}
            >
              <Nav.Item>
                <Nav.Link
                  eventKey={TabKeys.DESCRIPTION}
                  active={selectedTab === TabKeys.DESCRIPTION}
                >
                  Problem Description
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey={TabKeys.SUBMISSIONS}
                  active={selectedTab === TabKeys.SUBMISSIONS}
                >
                  Submissions
                </Nav.Link>
              </Nav.Item>
            </Nav>
            {
              selectedTab === TabKeys.DESCRIPTION && (
                <ProblemStatement
                  problemId={problemId}
                />
              )
            }
            {
              selectedTab === TabKeys.SUBMISSIONS && (
                <ProblemSubmissions
                  problemId={problemId}
                />
              )
            }
           <LoadingModal
             size={150}
             color="#1dbf72"
             loading={loading}
           />
            <ErrorModal
              onClose={() => setError(null)}
              message={error}
              show={!R.isNil(error) && loading === false}
            />
          </Col>
          <Col>
            <CodeEditor
              setEditorRef={setEditorRef}
              selectBackendLanguage={setLanguage}
              autoSavedCode={autoSavedCode}
              setEditorCurrentCode={code => setCurrentCode(code)}
            />
          </Col>
        </Row>
      </Container>
      <BottomNavBar
        onSubmit={() => onCodeSubmit()}
        onCodeSave={() => onCodeSave()}
      />
      <Loader loading={loading || autoSave} />
    </React.Fragment>
  );
}

export default ProblemSolveView;
