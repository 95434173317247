/* eslint-disable */
import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { useTable } from 'react-table';
import Table from 'react-bootstrap/Table';
import Loader from './Loader';
import { fetchAllProblemSubmissionsApi } from '../network/api';

const Columns = [
  {
    accessor: (row) => {
      console.log(`date = ${new Date(row.timestamp)}`);
      return new Date(row.timestamp).toString();
    },
    id: 'time',
    Header: 'Time',
  },
  {
    accessor: 'language',
    id: 'language',
    Header: 'Language',
    Cell: ({ value, row }) => {
      return (
        <a href={`/submissions/${row.original.submissionId}`}> { value } </a>
      );
    },
  },
  {
    accessor: 'verdict',
    id: 'verdict',
    Header: 'Verdict',
  },
];

function SubmissionsTable({ submissions }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns: Columns,
    data: submissions,
  });

  return (
    <Table striped bordered hover {...getTableProps()} >
      <thead>
      {
        headerGroups.map((headerGroup) => (
          <tr { ...headerGroup.getHeaderGroupProps()}>
            { headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}> { column.render('Header')} </th>
            ))}
          </tr>
        ))
      }
      </thead>
      <tbody {...getTableBodyProps()}>
        { rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

function ProblemSubmissions({ problemId }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    fetchAllProblemSubmissionsApi(problemId)
      .then(submissions => {
        setSubmissions(submissions);
        setLoading(false);
      })
      .catch(error => {
        if (error.response.status === 502) {
          setError('Unable to contact server!');
        }
        else {
          setError(`Unknown error occurred ${error.response.status}`);
        }
        setLoading(false);
      });
  }, [problemId]);

  return (
    <>
      {
        loading && (
          <Loader loading={loading} />
        )
      }
      {
        !loading && R.isNil(error) && (
          <SubmissionsTable submissions={submissions} />
        )
      }
      {
        !loading && !R.isNil(error) && (
          <div style={{ color: 'red' }}> { error } </div>
        )
      }
    </>
  )
}

export default ProblemSubmissions;
